import React, { useState } from 'react';
import { SliderArrowLeft, SliderArrowRight } from 'shared/svg/arrows';
import Swiper, { FreeMode, Navigation, Thumbs } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { GalleryWrapper, Image, SwiperWrapper } from './GallerySlider.styled';

interface GallerySliderProps {
    images: string[];
    removePadding?: boolean;
    removeMaxSlideHeight?: boolean;
}

export const GallerySlider = ({
    images,
    removePadding,
    removeMaxSlideHeight,
}: GallerySliderProps) => {
    const [thumbsSwiper, setThumbsSwiper] = useState<Swiper>();

    return (
        <GalleryWrapper
            removePadding={!!removePadding}
            removeMaxSlideHeight={!!removeMaxSlideHeight}
        >
            <SwiperWrapper
                loop={true}
                spaceBetween={32}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiperUpper"
            >
                {images?.map((image, index) => (
                    <SwiperSlide key={index}>
                        <Image src={image} />
                    </SwiperSlide>
                ))}
                <div className="swiper-button-prev swiper-button">
                    <SliderArrowLeft />
                </div>
                <div className="swiper-button-next swiper-button">
                    <SliderArrowRight />
                </div>
            </SwiperWrapper>
            <SwiperWrapper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={16}
                slidesPerView={3}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiperLower"
                breakpoints={{
                    769: {
                        slidesPerView: 6,
                        spaceBetween: 32,
                    },
                }}
            >
                {images?.map((image, index) => (
                    <SwiperSlide key={index}>
                        <Image src={image} />
                    </SwiperSlide>
                ))}
            </SwiperWrapper>
        </GalleryWrapper>
    );
};
