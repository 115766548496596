import { GallerySlider } from 'components/GallerySlider/GallerySlider';
import { UniversalHero } from 'components/UniversalHero/UniversalHero';
import HeroBg from 'images/galeria-hero-bg.png';
import Layout from 'layout/index';
import * as React from 'react';
import { getDisabledPages } from 'utils/utils';

interface GalleryPageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ pageContext, location }: GalleryPageProps) => {
    const galleryData =
        pageContext?.data?.wpInvestment?.investment?.galleryPage;

    const pageTitle = `${pageContext?.title} - ${galleryData?.title}`;

    return (
        <Layout
            displayPartnersPage={
                pageContext?.data?.wpInvestment?.investment?.enablePartnersPage
            }
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            pageTitle={pageTitle}
            location={location}
            displayBanner
            investmentDisabledPages={getDisabledPages(
                pageContext?.data?.wpInvestment?.investment,
            )}
        >
            <>
                <UniversalHero
                    background={
                        galleryData?.titleBackground?.sourceUrl
                            ? galleryData?.titleBackground?.sourceUrl
                            : HeroBg
                    }
                    text={galleryData?.description}
                    title={galleryData?.title}
                />
                {galleryData && (
                    <GallerySlider
                        images={galleryData?.gallery?.map(
                            ({ photo }: any) => photo?.sourceUrl,
                        )}
                        removeMaxSlideHeight
                    />
                )}
            </>
        </Layout>
    );
};
