import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';

export const GalleryWrapper = styled.div<{
    removePadding?: boolean;
    removeMaxSlideHeight?: boolean;
}>(
    ({ theme: { colors }, removePadding, removeMaxSlideHeight }) =>
        css`
            ${!removePadding &&
            css`
                padding: 160px 136px 100px;
            `};

            .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .swiper-slide img {
                align-items: center;
                display: flex;
                height: 100%;
                justify-content: center;
                object-fit: cover;
                width: 100%;
            }

            .swiper-slide {
                background-size: cover;
                background-position: center;
            }

            .mySwiperUpper {
                height: 80%;
                max-height: 620px;
                width: 100%;

                ${!!removeMaxSlideHeight &&
                css`
                    max-height: unset;
                `}
            }

            .mySwiperLower {
                box-sizing: border-box;
                height: 20%;
                max-height: 100px;
                margin: 32px 0 0 0;

                ${!!removeMaxSlideHeight &&
                css`
                    max-height: unset;
                `}
            }

            .mySwiperLower .swiper-slide {
                cursor: pointer;

                &:after {
                    content: '';
                    background: linear-gradient(
                        0deg,
                        rgba(255, 255, 255, 0.6),
                        rgba(255, 255, 255, 0.6)
                    );
                    width: 100%;
                    height: 100%;
                    position: absolute;
                }
                &:hover {
                    &:after {
                        background: linear-gradient(
                            0deg,
                            rgba(70, 107, 162, 0.7),
                            rgba(70, 107, 162, 0.7)
                        );
                    }
                }
            }

            .mySwiperLower .swiper-slide-thumb-active {
                cursor: auto;

                &:after {
                    display: none;
                }
            }

            .swiper-button {
                background-color: ${colors.white}90;
                border-radius: 50%;
                height: 48px;
                width: 48px;

                &:after {
                    display: none;
                }

                &:hover {
                    background-color: ${colors.primaryBlue500};
                    svg {
                        path {
                            stroke: white;
                        }
                    }
                }
            }

            .swiper-button-next {
                right: 32px;
            }

            .swiper-button-prev {
                left: 32px;
            }

            ${mediaQueries.sm} {
                ${!removePadding &&
                css`
                    padding: 56px 40px;
                `}

                .mySwiperLower {
                    margin: 16px 0 0;
                }
            }

            ${mediaQueries.xs} {
                ${!removePadding &&
                css`
                    padding: 56px 16px;
                `}

                .mySwiperLower {
                    display: none;
                }
            }
        `,
);

export const SwiperWrapper = styled(Swiper)``;

export const Image = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
